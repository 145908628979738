.item-list {
    margin: 24px 0;
}

.item-list a {
    text-decoration: none;

}

.item-list strong {
    color: #539bf5;
    font-size: 1.6rem;

}

.item-list p {
    color: #999999;
    font-size: 1.1rem;
    padding-bottom: 1rem;
}
