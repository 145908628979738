@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600&display=swap');

:root {
    --background-color: #1D2128;
    --line-color: #e5e5e533;;
    --header-color: #14171cbe;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Rokkitt', serif;
}

body {
    background-color: var(--background-color);
}

hr {
    background-color: var(--line-color);
    border: 1px solid var(--line-color);
    min-width: 30vw;
}
