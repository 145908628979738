header {
    width: 100%;
    height: 2.5rem;
    background-color: var(--header-color);

    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 2rem;
    font-weight: 500;
    color: white;
}
