:root {
    --border-color: #999999;
    --back-color: #1d1e20;
    --git-blue: #539bf5;
}

.content {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 3rem;
}

.background {
    height: 90vh;
    position: absolute;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.info {
    width: 60%;
    margin-right: 5rem;
}

input {
    border: 1px solid var(--border-color);
    border-radius: 22px;
    background: transparent;
    font-size: 1rem;
    padding: .5rem;
    min-width: 60%;
    margin-right: 1rem;
    outline: none;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

button {
    position: relative;
    border: 1px solid var(--git-blue);
    border-radius: 22px;
    background: transparent;
    color: white;
    font-size: 1rem;
    padding: .5rem;
    min-width: 20%;
    margin-right: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: pointer;
}

button:active {
  opacity: 0.8;
}


  button>span {
    position: absolute;
    border-radius: 100vmax;
  }

  .top {
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(
      90deg,
      transparent 50%,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49)
    );
  }

  .bottom {
    right: 0;
    bottom: 0;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgb(57, 255, 20),
      rgba(57, 255, 20, 0.5),
      transparent 50%
    );
  }


  .top {
    animation: animateTop 3s ease-in-out infinite;
  }

  .bottom {
    animation: animateBottom 3s ease-in-out infinite;
  }


  @keyframes animateTop {
    25% {
      width: 100%;
      opacity: 1;
    }

    30%,
    100% {
      opacity: 0;
    }
  }

  @keyframes animateBottom {
    0%,
    50% {
      opacity: 0;
      width: 0;
    }

    75% {
      opacity: 1;
      width: 100%;
    }

    76%,
    100% {
      opacity: 0;
    }
  }

.user-profile {
    display: flex;
    margin: 1.5rem 0;
}

.profile {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    border: 1px solid var(--border-color);
    margin-right: 2rem;
}

.user-profile, p {
    color: var(--border-color);
}

.user-profile h3 {
    font-size: 1.7rem;
    margin: 0;
}

.user-profile span {
    display: block;
    font-size: 1.1rem;
    margin: 0;
    color: var(--border-color);
}

.user-profile p {
    font-size: 1.2rem;
    margin: 0;
}

.repositories {
  font-size: 32px;
  color: whitesmoke;
  margin: 12px 0;
  text-align: center;
  font-weight: 400;
}

.user-profile a {
    text-decoration: none;
}

.repo {
  width: 89%;
  height: fit-content;
}

#tutorial {
  font-size: 1.2rem;
  margin-top: 1rem;
  cursor:default;
}


@media (max-width: 768px) {

  .content {
    align-items: center;
    justify-content: center;
  }

  .content p {
    text-align: justify;
  }

  .info {
    width: 90%;
    margin: 0;
  }

  .user-profile {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
  }

  .profile {
      margin: 0;
  }

  .repo {
    width: auto;
  }

  button {
    width: 30%;
    margin-right: 0;
  }

  .background {
    top: 0;
  }

}

@media (max-width: 400px) {
  input {
    width: 50%;
  }

  button {
    width: 25%;
  }
}
